import { Button, Icon, Stack, styled, Typography } from '@mui/material';
import {
  ActivityCycle,
  BillingPeriod,
  PaymentFrequency,
  PaymentFrequencyType,
  Product,
  ProductBillingType,
  ProductForm,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { Attention2Icon, CheckIcon, Loading, ModalSmall, NewTabIcon } from '@schooly/style';
import { isAfter } from 'date-fns';
import { FC, ReactNode, useCallback, useMemo } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';

import AccessDenied from '../../../../components/common/AccessDenied';
import { useProduct } from '../../../../context/product/WithProduct';
import AfterBillingStartedSvg from './Infographics/AfterBillingStarted.svg';
import AfterTermStartedSvg from './Infographics/AfterTermStarted.svg';
import BeforeBillingStartedSvg from './Infographics/BeforeBillingStarted.svg';
import { SchoolProductCreateModalContent, ViewState } from './SchoolProductCreateModalContent';

export const SchoolProductCreateModal: FC = () => {
  const { schoolId = '' } = useAuth();
  const navigate = useNavigate();
  const close = useCallback(() => navigate('/settings/products'), [navigate]);
  const intl = useIntl();
  const { getConfirmation } = useConfirmationDialog();
  const [searchParams] = useSearchParams();
  const {
    id,
    product,
    canCreate,
    isFetching,
    create,
    update,
    remove,
    isRemoving,
    isSaving,
    canDelete,
  } = useProduct();

  const { handleDelete, handleSave } = useMemo(() => {
    return {
      handleDelete:
        id && canDelete ? () => remove(id).then((result) => result && close()) : undefined,
      handleSave: async (data: ProductForm, frequencies: PaymentFrequency[]) => {
        if (!id) {
          await create(data);
          close();
          return;
        }

        const confirmationContent =
          product && getProductEditConfirmationContent({ product, frequencies, intl });

        if (
          confirmationContent &&
          !(await getConfirmation({
            content: confirmationContent,
          }))
        )
          return;

        await update(data);

        close();
      },
    };
  }, [id, canDelete, remove, close, product, intl, getConfirmation, update, create]);

  if (isFetching) {
    return (
      <ModalSmall open onClose={close}>
        <Loading />
      </ModalSmall>
    );
  }

  if (!canCreate) {
    return (
      <ModalSmall open onClose={close}>
        <AccessDenied />
      </ModalSmall>
    );
  }

  return (
    <SchoolProductCreateModalContent
      initialState={!!id && searchParams.has(ViewState.Variants) ? ViewState.Variants : undefined}
      schoolId={schoolId}
      product={product}
      onClose={close}
      onSave={handleSave}
      onDelete={handleDelete}
      isDeleting={isRemoving}
      isSaving={isSaving}
    />
  );
};

type FrequencyCheckResult =
  | 'unstartedBillingAndProduct'
  | 'startedBillingOnly'
  | 'startedBillingAndProduct';

const getProductEditConfirmationContent = ({
  product,
  frequencies,
  intl,
}: {
  product: Product;
  frequencies: PaymentFrequency[];
  intl: IntlShape;
}): ReactNode => {
  const { formatMessage } = intl;

  switch (product.type) {
    case ProductBillingType.OneOff:
      return null;

    case ProductBillingType.Recurring: {
      const title = (
        <Typography variant="h1">{formatMessage({ id: 'products-confirmChange' })}</Typography>
      );

      const productUsedFrequencyIds = product.types.reduce<string[]>((acc, type) => {
        return [...acc, ...type.variants.map((v) => v.prices.map((p) => p.frequency_id)).flat()];
      }, []);

      const usedFrequencies = frequencies.filter((f) => productUsedFrequencyIds.includes(f.id));
      if (!usedFrequencies.length) return null;

      const checkResults: FrequencyCheckResult[] = [];

      for (const fr of usedFrequencies) {
        if (fr.type === PaymentFrequencyType.OneOff) continue;

        let result: FrequencyCheckResult = 'unstartedBillingAndProduct';
        const billingAndProductCycles: [BillingPeriod, ActivityCycle][] = fr.billing_periods.map(
          (p, i) => [p, fr.activity_cycles[i]],
        );

        const now = new Date();

        for (const [billingPeriod, activityCycle] of billingAndProductCycles.slice().reverse()) {
          if (!isAfter(now, new Date(billingPeriod.generation_date))) {
            continue;
          }

          if (isAfter(now, new Date(activityCycle.start))) {
            result = 'startedBillingAndProduct';
            break;
          }

          result = 'startedBillingOnly';
          break;
        }

        checkResults.push(result);
      }

      if (checkResults.every((r) => r === 'unstartedBillingAndProduct')) {
        return (
          <InfographicsContainer>
            {title}
            <img className="info" src={BeforeBillingStartedSvg} alt="" />
            <Stack gap={2}>
              <RowItem textId="products-assignments-and-projected-fees-will-be-updated" />
              <RowItem textId="products-future-invoices-will-be-generated-based-on-the-new-details" />
            </Stack>
          </InfographicsContainer>
        );
      }

      if (checkResults.every((r) => r === 'startedBillingAndProduct')) {
        return (
          <InfographicsContainer>
            {title}
            <img className="info" src={AfterTermStartedSvg} alt="" />
            <Stack gap={2}>
              <RowItem textId="products-assignments-and-projected-fees-will-be-updated" />
              <RowItem textId="products-invoices-for-the-current" />
            </Stack>
          </InfographicsContainer>
        );
      }

      const hasXeroConnected = product.types.some(
        (t) => t.billing_connection.legal_entity_account.type === 'xero',
      );

      return (
        <InfographicsContainer>
          {title}
          <img className="info" src={AfterBillingStartedSvg} alt="" />
          <Stack gap={2}>
            <RowItem textId="products-assignments-and-projected-fees-will-be-updated" />
            <RowItem textId="products-invoices-for-less-than" />
            <RowItem textId="products-Invoices-for-future-cycles" />
            {hasXeroConnected && (
              <Stack
                sx={(theme) => ({
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: theme.spacing(1.5),
                  borderRadius: theme.spacing(1),
                  border: `1px solid ${theme.palette.warning.main}`,
                  backgroundColor: theme.palette.warning.superLight,
                  p: 2,
                })}
              >
                <Icon
                  sx={{
                    color: 'warning.main',
                    '& .svg-icon': { '& circle, & rect': { color: 'common.white' } },
                  }}
                >
                  <Attention2Icon />
                </Icon>
                <Stack flex={1}>
                  <Typography variant="h3" color="warning.main">
                    {formatMessage({ id: 'products-paidInvoicesAdjustedManually' })}
                  </Typography>
                </Stack>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ backgroundColor: '#fff', borderColor: `transparent !important` }}
                  startIcon={<NewTabIcon />}
                  onClick={() => window.open('https://www.xero.com/', '_blank')}
                >
                  {formatMessage({ id: 'products-goToXero' })}
                </Button>
              </Stack>
            )}
          </Stack>
        </InfographicsContainer>
      );
    }
    default:
      const _: never = product.type;
      return _;
  }
};

const InfographicsContainer = styled(Stack)(({ theme }) => ({
  paddingBottom: theme.spacing(3),
  '.info': { width: '100%', margin: theme.spacing(3, 0) },
}));

const RowItem: FC<{ textId: string }> = ({ textId }) => {
  return (
    <Stack flexDirection="row" alignItems="center" gap={2}>
      <Icon>
        <CheckIcon />
      </Icon>
      <Stack flex={1}>
        <Typography variant="h3" color="text.primary">
          <FormattedMessage
            id={textId}
            values={{
              u: ((chunks: string) => (
                <span style={{ textDecoration: 'underline' }}>{chunks}</span>
              )) as unknown as ReactNode,
            }}
          />
        </Typography>
      </Stack>
    </Stack>
  );
};
