import { Product, SORT_DIRECTION, useGetProductsListQuery } from '@schooly/api';
import { SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { useMemo, useState } from 'react';

import useSchoolYears from '../../../hooks/useSchoolYears';
import { RegistrationStatusWarning } from './RegistrationStatusWarning';

const PRODUCTS_LIST_PAGE_SIZE = 100;

interface RegistrationMissingStatusesProps {
  schoolId: string;
  selectedStatuses: {
    applies_from?: string;
    school_property_id?: string;
  }[];
}

export const useRegistrationMissingStatuses = ({
  schoolId,
  selectedStatuses,
}: RegistrationMissingStatusesProps) => {
  const { schoolYears } = useSchoolYears();
  const { activePropertiesMap } = useSchoolProperties({
    schoolId: schoolId,
    userType: SchoolUserRole.Student,
  });

  const { data } = useGetProductsListQuery(
    {
      schoolId,
      query: '',
      pageSize: PRODUCTS_LIST_PAGE_SIZE,
      sort: { columnTextId: 'name', direction: SORT_DIRECTION.ASC },
    },
    { refetchOnMount: 'always', enabled: !!schoolId },
  );

  const entries = useMemo(
    () => data?.pages.reduce<Product[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],

    [data?.pages],
  );

  const filteredProducts = useMemo(
    () => entries.filter((product) => product.obligatory),
    [entries],
  );

  const hasLastUnsuccessfullStatus = useMemo(() => {
    const lastSelectedStatusId =
      selectedStatuses?.[selectedStatuses.length - 1]?.school_property_id;
    const lastSelectedStatus = activePropertiesMap['status'].find(
      (status) => status.id === lastSelectedStatusId,
    );
    return lastSelectedStatus?.category?.name === 'Unsuccessful';
  }, [selectedStatuses, activePropertiesMap]);

  const [obligatoryProductStatuses, setObligatoryProductStatuses] = useState<
    RegistrationStatusWarning[]
  >([]);

  const findMissingStatuses = ({
    school_year_id,
    age_group_property_id,
    half_day,
  }: {
    school_year_id: string;
    age_group_property_id: string;
    half_day: boolean;
  }) => {
    const res: RegistrationStatusWarning[] = [];

    filteredProducts.forEach((product) => {
      const productFits = product.types.some((type) => {
        const schoolYear = schoolYears.find(
          (year) =>
            year.id === school_year_id &&
            year.start === type.active_from &&
            year.end === type.active_to,
        );

        if (!schoolYear) {
          return false;
        }

        return type.variants.some((variant) => {
          return (
            variant.age_groups.includes(age_group_property_id || '') &&
            !!variant.half_day === !!half_day
          );
        });
      });

      if (productFits) {
        product.triggers.forEach((trigger) => {
          const status = activePropertiesMap['status'].find(
            (status) => status.id === trigger.extra_data?.status,
          );
          const statusIndex = activePropertiesMap['status'].findIndex(
            (status) => status.id === trigger.extra_data?.status,
          );

          if (status) {
            res.push({
              statusId: status.id,
              statusName: status.name,
              statusIndex,
              shown: false,
            });
          }
        });
      }
    });

    setObligatoryProductStatuses(
      res.filter(
        (status, index, self) => index === self.findIndex((s) => s.statusId === status.statusId),
      ),
    );
  };

  const removeStatusWarning = (statusId: string) => {
    setObligatoryProductStatuses((prev) =>
      prev.map((status) =>
        status.statusId === statusId
          ? {
              ...status,
              shown: true,
            }
          : status,
      ),
    );
  };

  return {
    removeStatusWarning,
    findMissingStatuses,
    obligatoryProductStatuses,
    hasLastUnsuccessfullStatus,
    originalStatuses: activePropertiesMap['status'],
    filteredProducts,
  };
};
