import { Stack, Typography } from '@mui/material';
import {
  GetEnrollmentsResponse,
  StudentRolloverRequestParams,
  SyncUser,
  useGetEnrollments,
  useGetSchoolReEnrollmentStatus,
  useGetStudentsInconsistenciesQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { Counter, Loading, PlusIcon, SimpleButton } from '@schooly/style';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import AccessDenied from '../../../../components/common/AccessDenied';
import { ManageStudentRegistrations } from '../../../../components/common/ProfileModal/ManageStudentRegistrations';
import { useProfile } from '../../../../context/profile/useProfile';
import { getRegistrationsByTimePeriods } from '../../../../helpers/registrations';
import useSchoolYears from '../../../../hooks/useSchoolYears';
import { useRollover } from '../../../Rollover/useRollover';

type ProfileModalRegistrationsProps = {
  user: SyncUser;
};

export const ProfileModalRegistrations: FC<ProfileModalRegistrationsProps> = ({ user }) => {
  const navigate = useNavigate();
  const { schoolId = '', permissions } = useAuth();
  const { userType } = useProfile();
  const [rolloverParams, setRolloverParams] = useState<StudentRolloverRequestParams>();
  const { schoolYears } = useSchoolYears();
  const invalidateQueries = useInvalidateListQueriesFor(userType);

  const { navigateToRollover } = useRollover();

  const studentId = user.relation_id || '';

  const handleGetRolloverParams = useCallback(
    (data: GetEnrollmentsResponse) => {
      const [currentRegistrations, futureRegistrations, previousRegistrations] =
        getRegistrationsByTimePeriods(data.enrollments, 'student');

      if (futureRegistrations?.length) return;

      const registration =
        currentRegistrations?.find((r) =>
          r.statuses.some((s) => Boolean(s.school_property.category?.current)),
        ) ??
        previousRegistrations?.find((r) =>
          r.statuses.some((s) => Boolean(s.school_property.category?.current)),
        );

      if (!registration) return;

      schoolYears.forEach(({ id }, i, arr) => {
        if (id !== registration.school_year.id) return;
        const nextYear = arr[i + 1];
        if (!nextYear) return;
        setRolloverParams({
          student_ids: [studentId],
          year_from_id: registration.school_year.id,
          year_to_id: nextYear.id,
        });
      });
    },
    [schoolYears, studentId],
  );

  const { data, refetch } = useGetEnrollments(
    {
      schoolId: schoolId ?? '',
      studentId,
    },
    {
      enabled: !!schoolId && !!studentId,
      refetchOnMount: 'always',
      onSuccess: handleGetRolloverParams,
    },
  );

  const refetchEnrollments = useCallback(() => {
    refetch();
    invalidateQueries();
  }, [invalidateQueries, refetch]);

  const { data: reEnrollmentData } = useGetSchoolReEnrollmentStatus(schoolId ?? '', {
    enabled: !!schoolId,
  });

  const { data: inconsistencies } = useGetStudentsInconsistenciesQuery(rolloverParams!, {
    enabled: !!rolloverParams,
    refetchOnMount: 'always',
  });

  const hasPermission = useMemo(() => permissions.includes('registration_manager'), [permissions]);
  const canRollover = Boolean(inconsistencies?.can_rollover && hasPermission);

  const handleRollover = useCallback(() => {
    if (!rolloverParams) return;

    navigateToRollover(rolloverParams);
  }, [navigateToRollover, rolloverParams]);

  const handleOpenRegistrationsModal = useCallback(() => {
    navigate('registration');
  }, [navigate]);

  const handleEditRegistration = useCallback(
    (id: string) => {
      const isInitialRegistration = !!data?.enrollments.find((e) => e.id === id)?.initial;
      navigate(`registration?id=${id}&initial=${isInitialRegistration}`);
    },
    [navigate, data?.enrollments],
  );

  if (!schoolId) return null;

  if (data?.rollover_in_process) {
    return <AccessDenied />;
  }

  return (
    <>
      <Stack flexGrow={1}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2.5}>
          <Stack direction="row" alignItems="center">
            <Typography variant="h2">
              <FormattedMessage id="peopleDetail-registrations" />
            </Typography>
            {Boolean(data?.enrollments?.length) && <Counter>{data?.enrollments?.length}</Counter>}
          </Stack>

          <Stack direction="row" gap={2} alignItems="center">
            {canRollover && (
              <SimpleButton onClick={handleRollover}>
                <FormattedMessage id="students-AnnualRollover" />
              </SimpleButton>
            )}
            {hasPermission && (
              <SimpleButton startIcon={<PlusIcon />} onClick={handleOpenRegistrationsModal}>
                <FormattedMessage id="enrollment-AddRegistration" />
              </SimpleButton>
            )}
          </Stack>
        </Stack>
        {!data || !reEnrollmentData ? (
          <Loading />
        ) : (
          // TODO: remake deprecated components with MUI
          <ManageStudentRegistrations
            userType="student"
            registrations={data.enrollments}
            onEditRegistration={hasPermission ? handleEditRegistration : undefined}
            studentId={studentId}
            canRollover={canRollover}
            refetchEnrollments={refetchEnrollments}
            reEnrollmentData={reEnrollmentData}
          />
        )}
      </Stack>
    </>
  );
};
